import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import './styles/index.scss';
import './styles/bootstrap.scss';
import './styles/loader.scss';

import App from './app';
import configureStore, { history } from './store';
import LookUp from './components/lookUp';
import Alert from './components/common/global-alert';

const rootElement = document.getElementById('root');
const store = configureStore();

const render = Component => {
	return ReactDOM.render(
		<Provider store={store}>
			<LookUp>
				<Alert />
				<ConnectedRouter history={history}>
					<Component />
				</ConnectedRouter>
			</LookUp>
		</Provider>,
		rootElement
	);
};

render(App);

if (module.hot) {
	module.hot.accept('./app', () => {
		const NextApp = require('./app').default;
		render(NextApp);
	});
}
