import { createReducer } from '../../utils';
import * as actionTypes from './action-types';

const initialState = {
	show: false,
	style: 'global-alert'
};

export default createReducer(initialState, {
	[actionTypes.SET]: (state, action) => ({
		...state,
		...action.alert,
		show: true
	}),
	[actionTypes.CLOSE]: () => initialState
});
