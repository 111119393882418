import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Loading from '../common/loader';
import { init } from '../../store/reducers/lookUp';

const LookUp = ({ init, children, lookUp: { count, apiCount } }) => {
	useEffect(() => {
		init();
	}, []);
	if (count === apiCount) {
		return children;
	}

	return <Loading />;
};

const mapStateToProps = state => ({ lookUp: state.lookUp });

export default connect(mapStateToProps, { init })(LookUp);
