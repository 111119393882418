import { isAfter } from 'date-fns';
import { push } from 'connected-react-router';
import { createRemoteCallAction } from '../../utils';
import * as actionTypes from './action-types';

export const getRegions = () => async dispatch => {
	await dispatch(createRemoteCallAction({ fetch: actionTypes.SAVE_LOOKUP, name: 'regions' }, api => api.regions.getRegions)());
};

export const getRanks = () => async dispatch => {
	await dispatch(createRemoteCallAction({ fetch: actionTypes.SAVE_LOOKUP, name: 'ranks' }, api => api.ranks.getRanks)());
};

export const saveLogged = isLogged => ({
	type: actionTypes.SAVE_LOGGED,
	payload: { data: isLogged },
	name: 'isLogged'
});

export const logout = () => async dispatch => {
	dispatch(saveLogged(false));
	dispatch(push('/login'));
};

export const init = () => async dispatch => {
	await dispatch(getRegions());
	await dispatch(getRanks());
	const expiresAt = localStorage.getItem('expiresAt');
	if (expiresAt) {
		const isLogged = isAfter(new Date(expiresAt), new Date());
		dispatch(saveLogged(isLogged));
	} else {
		dispatch(saveLogged(false));
	}
};
