export default {
	NOT_FOUND: 'not_found',
	USER_NOT_FOUND: 'user_not_found',
	UNAUTHORIZED: 'unauthorized',
	UNKNOWN: 'unknown_error',
	VALIDATION: 'validation',
	BAD_REQUEST: 'bad_request',
	VALIDATION_FAILED: 'validation_failed',
	PHONE_VALIDATION_FAILED: 'phone_validation_failed',
	AUTH_FAILED: 'auth_failed',
	INVALID_CREDENTIALS: 'invalid_credentials',
	PASSWORD_COMPLEXITY_ERROR: 'password_complexity_error',
	USER_ALREADY_EXISTS: 'user_already_exists',
	USERNAME_TAKEN: 'username_taken',
	FORBIDDEN: 'forbidden',
	STRIPE_BALANCE_INSUFFICIENT: 'stripe_balance_insufficient',
	STRIPE_CARD_DECLINED: 'stripe_card_declined',
	STRIPE_COUPON_EXPIRED: 'stripe_coupon_expired',
	COUPON_INVALID: 'coupon_invalid',
	STRIPE_EXPIRED_CARD: 'stripe_expired_card',
	STRIPE_INCORRECT_CVC: 'stripe_INCORRECT_CVC',
	STRIPE_INCORRECT_NUMBER: 'stripe_incorrect_number',
	STRIPE_INVALID_CARD_TYPE: 'stripe_invalid_card_type',
	STRIPE_INVALID_CVC: 'stripe_invalid_cvc',
	STRIPE_INVALID_EXPIRY_MONTH: 'stripe_invalid_expiry_month',
	STRIPE_INVALID_EXPIRY_YEAR: 'stripe_invalid_expiry_year',
	STRIPE_INVALID_NUMBER: 'stripe_INVALID_NUMBER',
	STRIPE_PAYMENT_METHOD_UNACTIVATED: 'stripe_payment_method_unactivated',
	STRIPE_PAYMENT_METHOD_UNEXPECTED_STATE: 'stripe_payment_method_unexpected_state',
	STRIPE_PROCESSING_ERROR: 'stripe_processing_error',
	STRIPE_PRODUCT_INACTIVE: 'stripe_product_inactive',
	STRIPE_RESOURCE_MISSING: 'stripe_resource_missing'
};
