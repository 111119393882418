import { createReducer } from '../../utils';
import * as actionTypes from './action-types';

const initialState = {
	results: [],
	requestDetails: {},
	requestDetailsLoaded: false,
	numberOfPages: 2,
	currentPage: 1,
	requestId: '',
	isRing: false
};

export default createReducer(initialState, {
	[actionTypes.GET_EXCHANGE_REQUESTS]: (state, action) => ({
		...state,
		results: action.payload.data,
		isRing: action.name,
		numberOfPages: action.payload.numberOfPages
	}),
	[actionTypes.GET_EXCHANGE_REQUEST_BY_ID]: (state, action) => ({ ...state, requestDetails: action.payload.data, requestDetailsLoaded: true }),
	[actionTypes.UPDATE_EXCHANGE_REQUEST]: state => ({ ...state }),
	[actionTypes.CREATE_EXCHANGE_REQUEST]: state => ({ ...state }),
	[actionTypes.SET_ID_REQUEST_DETAILS]: (state, action) => ({ ...state, requestId: action.id }),
	[actionTypes.CLEAR_REQUEST]: () => initialState
});
