import ERRORS from '../constants/errors';
import generateAlertError from '../utils/generate-alert-error';
import { setAlert } from './reducers/alert';
import { logout } from './reducers/lookUp';

export const normalizeOptionsArray = (options = []) => {
	return options.map(optionObj => ({
		...optionObj,
		value: optionObj.name,
		label: optionObj.name
	}));
};

export function createReducer(initialState = null, actions = {}) {
	return (state = initialState, action) => {
		if (Object.prototype.hasOwnProperty.call(actions, action.type)) {
			return actions[action.type](state, action);
		}

		return state;
	};
}

export function createRemoteCallAction(actionTypes, method) {
	const action = params => async (dispatch, getState, { api }) => {
		const { fetch: fetchActionType, loading, name } = actionTypes;

		if (loading) {
			dispatch({ type: loading });
		}

		let response = {};

		try {
			response = await method(api)(params);
		} catch (e) {
			response = e.response;
		}

		if (response.status === 200 || response.status === 204) {
			let payload = response.data;
			dispatch({ type: fetchActionType, payload, name });
			return { payload };
		}

		const data = response.data;

		if (data.error && data.error.code) {
			switch (response.status) {
				case 401:
					dispatch(logout());
					return { error: data.error.code, messages: data };
			}
			return { error: data.error.code, messages: data };
		}

		switch (response.status) {
			case 400:
				return { error: ERRORS.BAD_REQUEST, messages: data };
			case 401:
				dispatch(logout());
				return { error: ERRORS.UNAUTHORIZED, messages: data };
			case 403:
				return { error: ERRORS.FORBIDDEN, messages: data };
			case 404:
				return { error: ERRORS.NOT_FOUND };
			case 422: {
				return { error: ERRORS.VALIDATION, messages: data };
			}
		}

		return { error: ERRORS.UNKNOWN, messages: data };
	};

	return action;
}

export const dispatchAlert = (dispatch, result, successMessage = 'Erfolg') => {
	const alert = {
		color: result.error ? 'danger' : 'success',
		text: result.error ? generateAlertError(result) : successMessage
	};

	return dispatch(setAlert(alert));
};
