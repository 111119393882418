import { post, get, update, remove } from './common';

export default {
	create: async params => post('/account', params),
	get: async () => get(`/account`),
	remove: async params => remove(`/account`, params),
	updatePersonalInfo: async params => update('/account', params),
	autoRenewal: async params => update('/account/billing/subscription/auto-renewal', params),
	deleteProcessing: async params => remove('/account/billing/subscription', params),
	confirmEmail: async params => post('/account/email-confirmation', params)
};
