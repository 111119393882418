import account from './account';
import auth from './auth';
import exchangeRequest from './exchange-request';
import regions from './regions';
import ranks from './ranks';
import pricing from './pricing';
import billing from './billing';

export default {
	account,
	auth,
	exchangeRequest,
	regions,
	ranks,
	pricing,
	billing
};
