import { createReducer } from '../../utils';
import * as actionTypes from './action-types';

const initialState = {
	regions: [],
	apiCount: 2,
	count: 0
};

export default createReducer(initialState, {
	[actionTypes.SAVE_LOOKUP]: (state, action) => ({
		...state,
		[action.name]: action.payload.data,
		count: state.count + 1
	}),
	[actionTypes.SAVE_LOGGED]: (state, action) => ({
		...state,
		[action.name]: action.payload.data
	})
});
