import ERRORS from '../constants/errors';

// eslint-disable-next-line complexity
export default response => {
	switch (response.error) {
		case ERRORS.UNAUTHORIZED: {
			if (response.messages) {
				return response.messages.error.message;
			}

			return 'You are not authorized to perform this action.';
		}

		case ERRORS.FORBIDDEN:
			return 'You are not authorized to perform this action.';
		case ERRORS.NOT_FOUND:
			return 'Nicht gefunden.';
		case ERRORS.USER_NOT_FOUND:
			return 'Benutzer wurde nicht gefunden.';
		case ERRORS.AUTH_FAILED:
			return 'Die Anmeldung ist fehlgeschlagen. Bitte überprüfen Sie Benutzername und Passwort.';
		case ERRORS.INVALID_CREDENTIALS:
			return 'Das Passwort ist ungültig';
		case ERRORS.VALIDATION_FAILED:
			return 'Überprüfung der Daten fehlgeschlagen.';
		case ERRORS.PHONE_VALIDATION_FAILED:
			return 'Falsche Telefonnummer';
		case ERRORS.PASSWORD_COMPLEXITY_ERROR:
			return 'Das Passwort muss mindestens 8 und kann maximal 26 Zeichen enthalten. Es muss einen Klein- und einen Großbuchstaben, sowie eine Zahl und ein Sonderzeichen enthalten.';
		case ERRORS.USER_ALREADY_EXISTS:
			return 'Ein Benutzer mit dieser E-Mail Adresse existiert bereits.';
		case ERRORS.STRIPE_BALANCE_INSUFFICIENT:
			return 'Kontostand nicht ausreichend.';
		case ERRORS.STRIPE_CARD_DECLINED:
			return 'Karte wurde abgelehnt.';
		case ERRORS.STRIPE_COUPON_EXPIRED:
			return 'Gutschein / Coupon ist nicht mehr gültig.';
		case ERRORS.STRIPE_EXPIRED_CARD:
			return 'Karte abgelaufen.';
		case ERRORS.STRIPE_INCORRECT_CVC:
			return 'Kartenprüfnummer (CVC) ungültig.';
		case ERRORS.STRIPE_INCORRECT_NUMBER:
			return 'Nummer nicht korrekt.';
		case ERRORS.STRIPE_INVALID_CARD_TYPE:
			return 'Ungültiger Kartentyp.';
		case ERRORS.STRIPE_INVALID_CVC:
			return 'Ungültige Kartenprüfnummer.';
		case ERRORS.STRIPE_INVALID_EXPIRY_MONTH:
			return 'Ungültiges Ablaufdatum (Monat)';
		case ERRORS.STRIPE_INVALID_EXPIRY_YEAR:
			return 'Ungültiges Ablaufdatum (Jahr)';
		case ERRORS.STRIPE_INVALID_NUMBER:
			return 'Nummer nicht korrekt.';
		case ERRORS.STRIPE_PAYMENT_METHOD_UNACTIVATED:
			return 'Zahlungsmethode nicht aktiv.';
		case ERRORS.STRIPE_PAYMENT_METHOD_UNEXPECTED_STATE:
			return 'Es ist ein unbekannter Fehler aufgetreten.';
		case ERRORS.STRIPE_PROCESSING_ERROR:
			return 'Verarbeitungsfehler.';
		case ERRORS.STRIPE_PRODUCT_INACTIVE:
			return 'Produkt nicht aktiv.';
		case ERRORS.STRIPE_RESOURCE_MISSING:
			return 'Gutscheincode nicht gültig.';
		case ERRORS.COUPON_INVALID:
			return 'Gutschein / Coupon ist nicht mehr gültig.';
		case ERRORS.USERNAME_TAKEN:
			return 'Benutzer mit einem solchen Benutzernamen existiert bereits.';

		default: {
			if (response.messages) {
				return response.messages.error.message;
			}

			return 'Something went wrong.';
		}
	}
};
