import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import { applyMiddleware, compose, createStore } from 'redux';

import api from '../api';
import reducer from './reducers';

export const APP_CLEAR_STORE = 'police-exchange/store#clear';
export const history = createBrowserHistory();

const extraArgument = { api };

if (process.env.NODE_ENV !== 'production' && module.hot) {
	module.hot.accept('../api', () => {
		extraArgument.api = api;
	});
}

const middleware = applyMiddleware(thunk.withExtraArgument(extraArgument), routerMiddleware(history));

const composeEnhancers = process.env.NODE_ENV === 'production' ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = history => (state, action) => {
	if (action.type === APP_CLEAR_STORE) {
		state = { routing: state.routing };
	}

	return reducer(history)(state, action);
};

export default function configureStore() {
	const store = createStore(rootReducer(history), {}, composeEnhancers(middleware));

	if (process.env.NODE_ENV !== 'production') {
		if (module.hot) {
			module.hot.accept('./reducers', () => {
				store.replaceReducer(reducer);
			});
		}
	}

	return store;
}
