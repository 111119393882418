import { createReducer } from '../../utils';
import * as actionTypes from './action-types';

const initialState = {
	origin: {},
	destination: {},
	loaded: false
};

export default createReducer(initialState, {
	[actionTypes.GET_RING_ORIGIN]: (state, action) => ({
		...state,
		origin: action.payload.data
	}),
	[actionTypes.GET_RING_DESTINATION]: (state, action) => ({
		...state,
		destination: action.payload.data
	}),
	[actionTypes.SET_RING_LOADED]: (state, action) => ({
		...state,
		loaded: true
	}),
	[actionTypes.CLEAR_REQUEST]: () => initialState
});
