import { createReducer } from '../../utils';
import * as actionTypes from './action-types';

const initialState = {
	data: [],
	loaded: false
};

export default createReducer(initialState, {
	[actionTypes.GET_PRICING]: (state, action) => {
		const {
			payload: { data },
			name: selectedId
		} = action;
		if (data) {
			data.forEach(item => {
				const selectLast = () => {
					item.paymentPlans.forEach((plan, index) => {
						if (index === item.paymentPlans.length - 1) {
							plan.selected = true;
						}
					});
				};
				if (item.paymentPlans) {
					if (selectedId) {
						const exist = item.paymentPlans.some(plan => plan.id === selectedId);
						if (exist) {
							item.paymentPlans.forEach(plan => {
								if (plan.id === selectedId) {
									plan.selected = true;
								}
							});
						} else {
							selectLast();
						}
					} else {
						selectLast();
					}
				}
			});
		}

		return { ...state, data, loaded: true };
	},
	[actionTypes.UPDATE_PRICING]: (state, action) => ({ ...state, data: action.data, loaded: true })
});
