import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as reduxFormReducer } from 'redux-form';
import account from './account/reducer';
import exchangeRequests from './exchange-requests/reducer';
import regions from './regions/reducer';
import lookUp from './lookUp/reducer';
import alert from './alert/reducer';
import pricing from './pricing/reducer';
import ringExchange from './ring-exchange/reducer';

const appReducer = history =>
	combineReducers({
		router: connectRouter(history),
		form: reduxFormReducer,
		account,
		exchangeRequests,
		ringExchange,
		regions,
		lookUp,
		alert,
		pricing
	});

const initialState = appReducer({}, {});
const rootReducer = history => (state = initialState, action = {}) => {
	return appReducer(history)(state, action);
};

export default rootReducer;
