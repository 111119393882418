import axios from 'axios';

axios.defaults.withCredentials = true;

export const instance = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
	headers: { 'Content-Type': 'application/json; charset=utf-8' },
	timeout: 60000
});

export const instanceEncoded = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
	headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
	timeout: 60000
});

export const get = async (url, params = { ignoreCache: true }) => instance.get(url, { params });
export const post = async (url, params) => instance.post(url, params);
export const postEncoded = async (url, params) => instanceEncoded.post(url, params);
export const update = async (url, params) => instance.put(url, params);
export const remove = async (url, data) => instance.delete(url, { data });
