import { createReducer, normalizeOptionsArray } from '../../utils';
import * as actionTypes from './action-types';

const initialState = {
	info: {},
	ranks: [],
	isEdit: false,
	loaded: false,
	confirmEmail: false,
	confirmEmailMessage: ''
};

export default createReducer(initialState, {
	[actionTypes.GET_ACCOUNT_INFO]: (state, action) => ({ ...state, info: action.payload.data, loaded: true }),
	[actionTypes.UPDATE_PERSONAL_INFO]: state => ({ ...state }),
	[actionTypes.CONFIRM_EMAIL]: state => ({ ...state, confirmEmail: true }),
	[actionTypes.CONFIRM_EMAIL_MESSAGE]: (state, action) => ({ ...state, confirmEmailMessage: action.message }),
	[actionTypes.GET_RANKS]: (state, action) => ({ ...state, ranks: normalizeOptionsArray(action.payload.data) }),
	[actionTypes.CLEAR_ACCOUNT]: () => initialState
});
