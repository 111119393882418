import React from 'react';
import { Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { closeAlert } from '../../../store/reducers/alert';

const GlobalAlert = ({ state, toggle }) => {
	const { text, color, show, style, close } = state;

	if (color === 'success' || close) {
		setTimeout(() => toggle(), 5000);
	}

	return (
		<Alert isOpen={show} color={color} toggle={toggle} className={`${style} text-center`}>
			{text}
		</Alert>
	);
};

const mapStateToProps = state => ({ state: state.alert });

export default connect(mapStateToProps, { toggle: closeAlert })(GlobalAlert);
