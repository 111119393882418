import React, { lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import Loader from './components/common/loader';
import './components/common/validators';
import { HelmetProvider } from 'react-helmet-async';

const Home = lazy(() => import('./components/home'));
const PoliceexList = lazy(() => import('./components/policeex-list'));
const ExchangeDetails = lazy(() => import('./components/exchange-details'));
const RingExchangeDetails = lazy(() => import('./components/ring-exchange'));
const PersonalInfo = lazy(() => import('./components/personal-info'));
const Signup = lazy(() => import('./components/signup'));
const Imprint = lazy(() => import('./components/imprint'));
const Login = lazy(() => import('./components/login'));
const Faq = lazy(() => import('./components/faq'));
const ResetPassword = lazy(() => import('./components/reset-password'));
const Verification = lazy(() => import('./components/verification'));
const Billing = lazy(() => import('./components/billing'));
const Privacy = lazy(() => import('./components/privacy'));
const CancellationPolicy = lazy(() => import('./components/cancellation-policy'));
const Agb = lazy(() => import('./components/agb'));
const CreateRequest = lazy(() => import('./components/create-request'));
const FindSwapPartner = lazy(() => import('./components/articles/find-swap-partner/index'));
const FindRightExchangePartner = lazy(() => import('./components/articles/find-right-exchange-partner/index'));
const NeuesPreismodell = lazy(() => import('./components/articles/neues-preismodell/index'));
const CookieBanner = lazy(() => import('./components/cookie-banner'));

const App = () => {
	return (
		<HelmetProvider>
			<Suspense fallback={<Loader />}>
				<div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
					<CookieBanner />
					<Switch>
						<Route exact path="/" render={props => <Home {...{ props }} />} />
						<Route exact path="/polizei-tauschpartner-fuer-laendertausch-finden" render={props => <FindSwapPartner {...{ props }} />} />
						<Route exact path="/polizei-ringtausch-tauschpartner-finden" render={props => <FindRightExchangePartner {...{ props }} />} />
						<Route exact path="/neues-preismodell" render={props => <NeuesPreismodell {...{ props }} />} />
						<Route exact path="/signup" render={props => <Signup {...{ props }} />} />
						<Route exact path="/imprint" render={props => <Imprint {...{ props }} />} />
						<Route exact path="/privacy" render={props => <Privacy {...{ props }} />} />
						<Route exact path="/cancellation-policy" render={props => <CancellationPolicy {...{ props }} />} />
						<Route exact path="/agb" render={props => <Agb {...{ props }} />} />
						<Route exact path="/faq" render={props => <Faq {...{ props }} />} />
						<Route exact path="/billing/:id" render={props => <Billing {...{ props }} />} />
						<Route exact path="/login" render={props => <Login {...{ props }} />} />
						<Route exact path="/password-reset" render={props => <ResetPassword {...{ props }} />} />
						<Route exact path="/exchange-requests" render={props => <PoliceexList {...{ props }} />} />
						<Route exact path="/create-request" render={props => <CreateRequest {...{ props }} />} />
						<Route exact path="/exchange-request/:id" render={props => <ExchangeDetails {...{ props }} />} />
						<Route exact path="/ring-exchange" render={props => <RingExchangeDetails {...{ props }} />} />
						<Route exact path="/personal-info" render={props => <PersonalInfo {...{ props }} />} />
						<Route exact path="/account/verification" render={props => <Verification {...{ props }} />} />
						<Redirect to="/" />
					</Switch>
				</div>
			</Suspense>
		</HelmetProvider>
	);
};

export default App;
